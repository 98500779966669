import React from 'react';
import './style.css';

const Question: React.FC = () => (
    <div className="question-container">
        <p className="question-text">What would you like to do today?</p>
        <p className="description">
            We appreciate your interest in Defined Home Services, LLC.
            <br />
            Please let us know how we can be of assistance.
        </p>

        <div className="options">
            <div className='container'>
                <a className="option-button" href="https://definedcleaning.com" target="_blank">
                    <div className="circle">
                        A
                    </div>
                    <span>Visit our website</span>
                </a>
            </div>
        </div>

        <h2>
            Leave a Review
        </h2>

        <div className="options">
            <div className='container'>
                <a className="option-button" href="https://g.page/r/CTMN70wLFLg-EBM/review" target="_blank">
                    <div className="circle">
                        B
                    </div>
                    <span>Leave a Google review</span>
                </a>
            </div>
            <div className='container'>
                <a className="option-button" href="https://www.yelp.com/writeareview/biz/yuvhXHR3M3q4u0UhYIIY1A?review_origin=writeareview-search" target="_blank">
                    <div className="circle">
                        C
                    </div>
                    <span>Leave a Yelp review</span>
                </a>
            </div>
        </div>
        <div className="options">
            <h2>Window Cleaning</h2>
            <div className='container'>
                <a className="option-button" href="https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=40345092" target="_blank">
                    <div className="circle">
                        D
                    </div>
                    <span>Schedule a window cleaning</span>
                </a>
            </div>
            <div className='container'>
                <a className="option-button" href="https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=59170509" target="_blank">
                    <div className="circle">
                        E
                    </div>
                    <span>Schedule a window cleaning estimate</span>
                </a>
            </div>
            <h2>Solar Screens</h2>
            <div style={{ marginBottom: 50 }} className='container'>
                <a className="option-button" href="https://app.acuityscheduling.com/schedule.php?owner=20558893&appointmentType=44616787" target="_blank">
                    <div className="circle">
                        F
                    </div>
                    <span>Schedule a solar screen estimate</span>
                </a>
            </div>

        </div>
    </div>
);

export default Question;